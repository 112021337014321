import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import styles from "@/styles/InactivityWarning.module.scss";
import { useTranslation } from "react-i18next";
import { Icon } from "@app-components";

export default function OfflineOverlay() {
  const { t } = useTranslation();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (!isOffline) return null;

  return (
    <AnimatePresence>
      <motion.div
        className={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className={styles.topContainer}>
          <Icon name="wifi_off" className={styles.counter} />
          <h1>{t("Offline.Header")}</h1>
          <p>{t("Offline.Text")}</p>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
