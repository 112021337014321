import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";
import { InputField } from "../InputField";
import type { InputFieldProps } from "../InputField/InputField";
import { useTranslation } from "react-i18next";
import styles from "./PhoneInput.module.scss";
import {
  parsePhoneNumberFromString,
  CountryCode as LibCountryCode,
} from "libphonenumber-js";

const getFlagEmoji = (countryCode: string) =>
  countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));

type CountryCode = {
  code: string;
  dialCode: string;
};

// Function to get the ISO country code from the dial code
const getISOCodeFromDialCode = (
  dialCode: string,
  countries?: CountryCode[],
): LibCountryCode | undefined => {
  return countries
    ?.find((country) => country.dialCode === dialCode)
    ?.code.toUpperCase() as LibCountryCode;
};

// Function to validate phone numbers
export const isValidPhoneNumber = (
  phoneNumber: string,
  countryCode: LibCountryCode | undefined,
) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);
  return parsedNumber?.isValid() ?? false;
};

type PhoneInputProps = Omit<
  InputFieldProps,
  "type" | "prepend" | "onChange"
> & {
  countries?: CountryCode[];
  otherOptionText?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>, countryCode: string) => void;
  onValidationChange?: (isValid: boolean) => void;
};

export function PhoneInput({
  countries,
  value,
  otherOptionText = "Other",
  onChange,
  onBlur,
  dangerText,
  onValidationChange,
  ...props
}: PhoneInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const updateAllowSubmission = (isValid: boolean) => {
    if (onValidationChange) {
      onValidationChange(isValid); // Pass validation state to the parent
    }
  };

  const findMatchingCountryCode = (phone?: string) => {
    if (!phone || !countries || phone.length < 4) return "";
    // Sort country codes by length (longest first)
    const sortedCountries = [...countries].sort(
      (a, b) => b.dialCode.length - a.dialCode.length,
    );
    return (
      sortedCountries.find((c) => phone.startsWith(c.dialCode))?.dialCode || ""
    );
  };

  const [countryCode, setCountryCode] = useState(
    value ? findMatchingCountryCode(value) : "+47",
  );

  useEffect(() => {
    const detectedCode = findMatchingCountryCode(value);
    if (detectedCode && detectedCode !== countryCode) {
      setCountryCode(detectedCode);
    }
  }, [value, countries, countryCode]);

  const escapedCountryCode = countryCode.replace(/\+/g, "\\+");
  const phoneNumber = value?.replace(new RegExp(`^${escapedCountryCode}`), "");

  const triggerOnChange = (
    originalEvent: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    newValue: string,
    newCountryCode = countryCode,
  ) => {
    const modifiedEvent = Object.create(originalEvent);
    modifiedEvent.target = {
      ...originalEvent.target,
      value: newValue.replace(/(?!^)\+/g, ""), // replace all + except the first one
    };
    onChange?.(modifiedEvent, newCountryCode);
  };

  const triggerOnBlur = (
    originalEvent: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    newValue: string,
  ) => {
    if (!onBlur) return;
    // Manually create a blur event for the input field to trigger validation
    const syntheticEvent = {
      ...originalEvent,
      target: {
        ...inputRef.current,
        value: newValue.replace(/(?!^)\+/g, ""), // replace all + except the first one,
      }, // Redirect target to input field
      currentTarget: inputRef.current,
    } as FocusEvent<HTMLInputElement>;
    onBlur(syntheticEvent);

    const isoCountryCode = getISOCodeFromDialCode(countryCode, countries);
    if (!isValidPhoneNumber(newValue, isoCountryCode)) {
      updateAllowSubmission(false);
      setError(t("VALIDATION.INVALID_PHONE_FORMAT"));
    } else {
      updateAllowSubmission(true);
      setError(undefined);
    }
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newPhone = e.target.value;
    let newCountryCode = countryCode;
    let hasDetectedCountryCode = false;
    const userIsTypingCountryCode = newPhone.startsWith("+");

    if (!newPhone) {
      triggerOnChange(e, "", "");
      setError(undefined);
      return;
    }

    // Detect if the user is manually typing a country code
    if (newPhone.length >= 4 && userIsTypingCountryCode) {
      const detectedCode = findMatchingCountryCode(newPhone);
      if (detectedCode) {
        setCountryCode(detectedCode);
        newCountryCode = detectedCode;
        newPhone = newPhone.replace(detectedCode, "");
      } else {
        setCountryCode("");
        newCountryCode = "";
      }
      hasDetectedCountryCode = !!detectedCode;
    }

    const fullPhoneNumber = `${userIsTypingCountryCode && !hasDetectedCountryCode ? "" : newCountryCode}${newPhone}`;

    // Convert dial code to ISO country code for validation
    const isoCountryCode = getISOCodeFromDialCode(newCountryCode, countries);

    // Clear error immediately on valid input
    if (isValidPhoneNumber(fullPhoneNumber, isoCountryCode)) {
      updateAllowSubmission(true);
      setError(undefined);
    } else {
      updateAllowSubmission(false);
    }

    triggerOnChange(e, fullPhoneNumber, newCountryCode);
  };

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newCountryCode = e.target.value;
    const newValue = `${newCountryCode}${phoneNumber}`;
    setCountryCode(newCountryCode);
    if (!newCountryCode || phoneNumber) {
      triggerOnChange(e, newValue, newCountryCode);
    }
    triggerOnBlur(e, newValue);
  };

  useEffect(() => {
    if (value) {
      setCountryCode(findMatchingCountryCode(value));
    }
  }, [value]);

  return (
    <InputField
      {...props}
      ref={inputRef}
      type="tel"
      value={phoneNumber}
      onChange={handlePhoneChange}
      onBlur={(e) => triggerOnBlur(e, `${countryCode}${e.target.value}`)}
      prepend={
        countries?.length && (
          <select
            value={countryCode}
            onChange={handleCountryChange}
            onClick={(e) => e.stopPropagation()}
            className={`${styles.countryCodeSelect} ${props.dark ? styles.dark : ""}`}
          >
            {countries?.map((country) => (
              <option key={country.code} value={country.dialCode}>
                {getFlagEmoji(country.code)} {country.dialCode}
              </option>
            ))}
            <option value="">{otherOptionText}</option>
          </select>
        )
      }
      dangerText={error || dangerText}
    />
  );
}
