import { BASE_URL } from "@/lib/constants";
import { useQuery } from "@tanstack/react-query";

export const preregAttendeesNamesOptions = (groupId: string | undefined) => ({
  queryKey: ["prereg-attendees-names", groupId],
  queryFn: async () => {
    const response = await fetch(
      `${BASE_URL}/getPreRegisteredAttendeesNames?groupId=${groupId}`,
    );
    if (response.ok) {
      return (await response.json()) as unknown as string[];
    } else {
      throw new Error(
        `Failed to fetch attendees names ${JSON.stringify(await response.json())}`,
      );
    }
  },
});

export const usePreRegAttendeesNames = (
  groupId: string | undefined,
  enabled: boolean,
) =>
  useQuery({
    ...preregAttendeesNamesOptions(groupId),
    enabled,
  });
