import { Button } from "@app-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import styles from "@/styles/HiddenRefreshButton.module.scss";

export default function HiddenRefreshButton() {
  const { t, i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const [clickCount, setClickCount] = useState(0);
  const [showButton, setShowButton] = useState(false);

  const hardRefreshApp = () => {
    i18n.changeLanguage("no");
    setLocation(`/?entranceId=${localStorage.getItem("entranceId")}`);
    window.location.reload();
  };

  useEffect(() => {
    const handleClick = () => {
      setClickCount((prev) => prev + 1);
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    let resetTimer: NodeJS.Timeout;
    if (clickCount > 0) {
      // Reset click count if no click happens within 1 second
      resetTimer = setTimeout(() => setClickCount(0), 1000);
    }
    if (clickCount === 10) {
      setShowButton(true);
      setClickCount(0); // Reset count after triggering
    }
    return () => clearTimeout(resetTimer);
  }, [clickCount]);

  useEffect(() => {
    if (showButton) {
      const hideButtonTimer = setTimeout(() => setShowButton(false), 10000); // Hide after 10s
      return () => clearTimeout(hideButtonTimer);
    }
  }, [showButton]);

  return showButton ? (
    <Button className={styles.refreshBtn} dark large onClick={hardRefreshApp}>
      {t("Registration.Failed.HardReload")}
    </Button>
  ) : null;
}
