import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { BASE_URL, CURR_VERSION } from "@/lib/constants";
import { env } from "@/lib/utils";

const isDevEnv = env("VITE_ENVIRONMENT") === "dev";

export const useVersionNumber = (): UseQueryResult<string> => {
  return useQuery({
    queryKey: ["version"],
    queryFn: async () => {
      if (isDevEnv) {
        return CURR_VERSION;
      }
      try {
        const response = await fetch(`${BASE_URL}/getVersionNumber`);
        return await response.json();
      } catch (error) {
        console.error(error);
        return CURR_VERSION;
      }
    },
    refetchInterval: isDevEnv ? false : 1000 * 60 * 5, // 5 mins
  });
};
